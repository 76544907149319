<template>
  <div>
    <v-row class="fill-height px-md-6 rounded">
      <v-col cols="12">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Aujourd'hui
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Jour</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semaine</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mois</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 Jours</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { functions } from "../../utils/constant.js";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.getEvents();
  },
  watch: {
    list() {
      this.getEvents();
    },
  },
  data() {
    return {
      focus: "",
      type: "month",
      dialog: false,
      typeToLabel: {
        month: "Mois",
        week: "Semaine",
        day: "Jour",
        "4day": "4 Jours",
      },
      ModalText: {},
      events: [],
    };
  },

  methods: {
    getEvents() {
      this.events = [];
      for (let event of this.list) {
        this.events.push({
          name:
            event.type == "course"
              ? `Cours | Salle ${event.hall.name} `
              : `Partiel | Salle ${event.hall.name}`,
          date: event.date,
          //   date: "2022-07-01",
          startHour: event.startHour,
          start: new Date(`${event.date} ${event.startHour}`),
          endHour: event.endHour,
          end: new Date(`${event.date} ${event.endHour}`),
          duration: event.duration,
          type: event.type,
          hall: event.hall,
          timed: true,
          hourlyMass: event.hourlyMass,
          color: event.color,
          _id: event._id,
        });
      }
    },
    showEvent({ nativeEvent, event }) {
      const edit = async () => {
        this.selectedEvent = event;
        this.event = functions.getItemFromList(this.list, event._id, "_id");

        this.$emit("edit", this.event);
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.dialog = true))
        );
      };

      if (this.dialog) {
        this.dialog = false;
        this.selectedEvent = undefined;
        this.selectedElement = undefined;
        requestAnimationFrame(() => requestAnimationFrame(() => edit()));
      } else {
        edit();
      }

      nativeEvent.stopPropagation();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  },
};
</script>

<style>
</style>